<template>
  <transition name="slide-top">
    <div v-if="value" class="ui-loader-big__backdrop loader">
      <div class="loader__header">
        <img class="loader__header-logo" src="@/assets/svg/logo/with-text.svg" alt="" />
        <img class="loader__header-icon-close" src="@/assets/svg/close/black.svg" alt="" v-if="closeIcon" @click="$emit('input', false)" />
      </div>
      <img class="loader__main" src="@/assets/images/image-qr.png" alt="" />
      <img class="loader__footer" src="@/assets/images/image-loader-big.png" alt="" />
    </div>
  </transition>
</template>

<script>
export default {
  name: "UiLoaderBig",

  props: {
    value: {
      type: Boolean,
      default: false
    },
    closeIcon: {
      type: Boolean,
      default: true
    },
  }
}
</script>

<style lang="scss" scoped>
.loader {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(7.33435px);
  display: flex;
  flex-direction: column;
  gap: 200px;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 50px 60px;
  transition: 0.5s;

  @media (max-width: 768px) {
    padding: 30px 20px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-icon-close {
      cursor: pointer;
      width: 25px;
      height: 25px;

      @media (max-width: 500px) {
        display: none;
      }
    }

    &-logo {
      @media (max-width: 500px) {
        width: 342.59px;
        height: 103.08px;
      }
    }

    @media (max-width: 500px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      display: inline-block;
      width: auto;
    }
  }

  &__main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    animation: prixClipFix 3s linear infinite ;

    @media (max-width: 500px) {
      display: none;
    }
  }

  &__footer {
    position: absolute;
    bottom: -3px;
    left: 0;

    @media (max-width: 500px) {
      display: none;
    }
  }
}

.fade-enter, .fade-leave-to {
  opacity: 0;

}

@keyframes prixClipFix {
  0%   {opacity: 1}
  25%  {opacity: .5}
  50%  {opacity: 1}
  75%  {opacity: .5}
  100% {opacity: 1}
}
</style>
